import React from "react";
import { withRouter, Link } from "react-router-dom";
import classnames from "classnames";
import { BaseLayout, Loading } from "components";
import { getActionList } from "services";
import styles from "./styles.module.scss";
import { throttle } from "lodash";
import { formatTime } from "utils";

class ActivityCenter extends React.PureComponent {
  state = {
    result: [],
    loading: true,
    token: null,
    phone: null,
    platform: "android",
    channel: "CF",
    adPosition: 12,
    pageNo: 0,
    pageSize: 5,
    totalPage: 0,
    totalSize: 0,
    more: false,
  };
  constructor() {
    super();
    this.scrollHandler = throttle(this.scroll, 300);
  }
  componentDidMount() {
    const query = {};
    this.props.location.search.replace(
      /([^?&=]+)=([^&]+)/g,
      (_, k, v) => (query[k] = v)
    );
    this.setState(
      {
        ...this.state,
        ...query,
      },
      function() {
        const {
          platform,
          pageNo,
          pageSize,
          phone,
          adPosition,
          channel,
        } = this.state;
        this.getList({
          pageNo: pageNo + 1,
          pageSize,
          platform,
          account: phone ? phone : null,
          adPosition,
          channel,
        });
      }
    );

    window.addEventListener("scroll", this.scrollHandler, false);
  }
  getList = async (params) => {
    try {
      let data = {};
      const res = await getActionList(params);
      if (res.success) {
        data = {
          ...res.data,
        };
      }
      this.setState({
        ...data,
        pageNo: this.state.pageNo + 1,
        loading: false,
      });
    } catch (error) {
      console.log(error);
    }
  };
  scroll = (e) => {
    const { pageNo, pageSize, totalPage, totalSize } = this.state;
    const winH = window.innerHeight;
    const offset = window.pageYOffset;
    const bodyH = document.body.clientHeight;
    if (
      pageNo <= totalPage &&
      pageNo * pageSize < totalSize &&
      winH + offset + 10 > bodyH
    ) {
      this.getMore();
    }
  };
  getMore = async () => {
    this.setState(
      {
        more: true,
      },
      async function() {
        const {
          platform,
          pageNo,
          pageSize,
          phone,
          adPosition,
          channel,
        } = this.state;
        let data = {};
        const res = await getActionList({
          pageNo: pageNo + 1,
          pageSize,
          platform,
          account: phone,
          adPosition,
          channel,
        });
        if (res.success) {
          data = {
            ...res.data,
          };
        }
        this.setState({
          ...this.state,
          ...data,
          result: [...this.state.result, ...res.data.result],
          pageNo: this.state.pageNo + 1,
          more: false,
        });
      }
    );
  };
  render() {
    const { result: list, loading, pageNo, totalPage, more } = this.state;
    return (
      <BaseLayout>
        {loading ? (
          <Loading />
        ) : (
          <div className={styles.list}>
            {list.map((item) => {
              const start = formatTime(item.startTime);
              const end = formatTime(item.endTime);
              const isTerminate = item.endTime - Date.now() > 0;
              return (
                <div key={item.id} className={styles.list_item}>
                  <Link
                    target="_blank"
                    to={{
                      pathname: item.linkUrl + this.props.location.search,
                    }}
                  >
                    {/* eslint-disable-next-line */}
                    <a className={styles.thumb}>
                      <img src={item.imgUrl} alt={item.name} />
                    </a>
                  </Link>
                  <div className={styles.summary}>
                    <div>
                      <h3>{item.name}</h3>
                      <p className={styles.time}>
                        {start.format} - {end.format}
                      </p>
                    </div>
                    <span
                      className={classnames({ [styles.active]: isTerminate })}
                    >
                      {isTerminate ? "进行中" : "已结束"}
                    </span>
                  </div>
                </div>
              );
            })}
            {more ? <div className={styles.loading}>加载中...</div> : null}
            {pageNo === totalPage ? (
              <div className={styles.loading}>没有更多了</div>
            ) : (
              <div className={styles.loading}>暂无数据</div>
            )}
          </div>
        )}
      </BaseLayout>
    );
  }
}

export default withRouter(ActivityCenter);
