import Request from "./request";
import { limitNews, linkConfig } from "config";

/**
 * 获取资讯
 * @param {Number} id 资讯分类id
 * @param {Object} body 资讯传输参数
 */
export const getNews = async (id, body = {}) =>
  Request({
    url: `${linkConfig.msite}/news/v2/more/${id}`,
    body: {
      limit: limitNews,
      ...body
    }
  });

/**
 * 获取新闻详情
 */
export const getNewsDetail = async id =>
  Request({
    url: `${linkConfig.msite}/news/${id}`
  }).catch(err => err);

/**
 * 获取子分类
 */
export const getCategory = async pid =>
  Request({
    url: `${linkConfig.msite}/news/v2/childtype/${pid}`
  }).catch(err => err);

export const getAccount = async () =>
  Request({
    url: "http://www.wstjy.cn/app/btcchy/account.json"
  }).catch(err => err);

/**
 * 活动列表
 */
export const getActionList = async params =>
  Request({
    method: "post",
    url: `${linkConfig.mis}/public/activityCenter/page`,
    body: {
      ...params
    }
  }).catch(err => err);
/**
 * 获取安卓下载包 列表
 */
export const getAndroidList = async params =>
  Request({
    method: "post",
    url: `${linkConfig.androidUrl}/public/app/getChannelPackage`,
    body: {
      ...params
    }
  }).catch(err => err);


  /**
 * 推送消息详情
 * @param {*} id 
 * @param {*} customerNo  账号
 */
export const getMsgDetail = async (id, customerNo) =>
Request({
  url: `${linkConfig.mis}/public/msgPush/msgDetailInfo/${id}`,
  body: {
    customerNo
  }
})
