import React from "react";
export default () => (
  <div className="flex-container flex-center full-screen layout-vertical">
    <div className="icon-404" />
    <p className="tips sm-size">没有找到你访问的资源。</p>
    <p className="tips sm-size">
      <a className="link" href="https://www.bt.cc">
        返回网站首页
      </a>
    </p>
  </div>
);
