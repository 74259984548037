import React from "react";
import { withRouter } from "react-router-dom";
import { BaseLayout, Loading, List } from "components";
import { getNews } from "services";
const limitNews = 20;
class Cat extends React.PureComponent {
  state = {
    id: null,
    title: "",
    loading: true,
    list: [],
    more: true
  };
  componentDidMount() {
    const { history, match } = this.props;
    const { params } = match;
    const search = {};
    if (window) {
      decodeURIComponent(window.location.search).replace(
        /([^?&=]+)=([^&]+)/g,
        (_, k, v) => (search[k] = v)
      );
    }
    document.title = search["title"] || "帮助中心";
    if (!params.id) {
      history.goBack();
    }
    this.init(params.id, search["title"]);
  }
  init = async (id, title) => {
    try {
      const res = await getNews(id, { limit: limitNews });
      if (res.success) {
        this.setState({
          id,
          title,
          loading: false,
          list: [...res.ch_msg],
          more: res.ch_msg.length >= limitNews
        });
      }
    } catch (error) {}
  };
  getMore = async item => {
    let ctime = 0;
    if (item.list.length > 0) {
      ctime = item.list[item.list.length - 1].ctime;
    }
    const res = await getNews(item.id, { ctime, limit: 20 });
    if (res.success) {
      this.setState({
        list: [...this.state.list, ...res.ch_msg],
        more: res.ch_msg.length >= limitNews,
        loading: false
      });
    }
  };
  render() {
    const { id, loading, list, more } = this.state;
    return (
      <BaseLayout>
        {loading ? (
          <Loading />
        ) : (
          <React.Fragment>
            {/* <Navigation title={title} /> */}
            <List list={list} />
            {more ? (
              <button
                className="block-show btn-text"
                onClick={() => this.getMore({ id, list })}
              >
                查看更多
              </button>
            ) : null}
          </React.Fragment>
        )}
      </BaseLayout>
    );
  }
}

export default withRouter(Cat);
