import React from "react";
import { linkConfig } from "config";
import styles from "./styles.module.scss";
class Detail extends React.Component {
  state = {
    title: "全球运营最久的交易所",
    platform: "btcc",
    url:linkConfig.download.ios.locale,
  };
  render() {
    return (
      <div className={styles.local_detail}>
        <div className={styles.local_banner}>
          <h2>全球顶尖的差价合约交易平台</h2>
          <p>
            <a href={this.state.url}>立即安装</a>
          </p>
          <p className={styles.atten}>
            提示安装“Anto Global” 时，请点击安装按钮
          </p>
          <p className={styles.atten}>如果下载不了APP，请先删除原有APP</p>
        </div>
        <div className={styles.local_body}>
          <h3>安装完成后，请按以下操作</h3>
          <p>
            <b>打开 &#8594;「设置」</b>
            <b>&#8594;「通用」</b>
            <b>&#8594;「设备管理器」</b> <br />
            找到Anto Global App的描述文件，设置为信任。
          </p>
          <div className={styles.exam_1} />
          <div className={styles.exam_2} />
          {/* <img
            alt={this.state.title}
            src={`http://www.wstjy.cn/app/iosazjc/${this.state.platform}/t_1.jpg`}
          />
          <img
            alt={this.state.title}
            src={`http://www.wstjy.cn/app/iosazjc/${this.state.platform}/t_2.jpg`}
          /> */}
          <p>设置完成后，就可以使用“Anto Global App”了</p>
        </div>
      </div>
    );
  }
}
export default Detail;
