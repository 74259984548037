import React from "react";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import styles from "./index.module.scss";
function List({ list }) {
  return (
    <ul className={styles.list}>
      {list.map(item => (
        <li key={item.id}>
          <Link to={`/detail/${item.id}`}>
            <span className={styles.list_item}>
              {item.title}
              <i className="icon icon-arrow-right" />
            </span>
          </Link>
        </li>
      ))}
    </ul>
  );
}
List.propTypes = {
  list: PropTypes.array.isRequired
};
export default List;
