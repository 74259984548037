import React from "react";
import { getNewsDetail } from "services";
import { BaseLayout, Loading } from "components";
import styles from "./styles.module.scss";
class GuideDetail extends React.PureComponent {
  state = {
    loading: true
  };
  componentDidMount() {
    const { history, match } = this.props;
    const { params } = match;
    if (!params.id || !Number.isInteger(parseInt(params.id))) {
      history.goBack();
    }
    this.getDetail();
  }
  getDetail = async () => {
    const { match } = this.props;
    const { params } = match;
    const res = await getNewsDetail(params.id);
    if (res.success && res.ch_msg.length > 0) {
      this.setState({
        loading: false,
        ...res.ch_msg[0]
      });
    }
  };
  render() {
    const { loading, title, content } = this.state;
    return (
      <BaseLayout>
        {loading ? (
          <Loading />
        ) : (
          <div className="plane full-screen">
            <h2 className="page-title">{title}</h2>
            {/* <p className={styles.time}>{ctime}</p> */}
            <div className={styles.detail}>
              <div
                className="page-content"
                dangerouslySetInnerHTML={{ __html: content }}
              />
            </div>
          </div>
        )}
      </BaseLayout>
    );
  }
}
export default GuideDetail;
