import React from "react";
import PropTypes from "prop-types";

export default function Footer({ title }) {
  return <div className="tips">© Copyrights © {new Date().getFullYear()} {title} 版权所有 </div>;
}
Footer.defaultProps = {
  title: "Anto Global"
};
Footer.propTypes = {
  title: PropTypes.string
};
