import React from "react";
import PropTypes from "prop-types";
import styles from "./styles.module.scss";
class Statement extends React.PureComponent {
  static defaultProps = {
    title: "BTCC Global"
  };
  state = {
    more: false
  };
  showAll = () => {
    this.setState({
      more: true
    });
  };
  render() {
    const { more } = this.state;
    const { title } = this.props;
    return (
      <div className={styles.rules}>
        <h3>免责声明</h3>
        <p>
          1.互联网故障：互联网的运作不在{title}
          控制范围之内，因此不能确保通过互联网的接收和发放的信号，客户电子设备的结构或联接的可靠性，
          {title}绝不对互联网上交易中出现的通讯故障、错误或延迟负责。
        </p>
        <p>
          2.市场风险和网上交易：保证金交易涉及相当大的风险，其并非对每个投资者都适合。请参照风险披露声明，了解有关风险的详细资料。虽然网上交易为客户带来很多方便或更有效率，但它并不降低保证金交易本身的风险。因此客户在交易前，须准备承担此风险。
          {!more ? (
            <span onClick={this.showAll} className={styles.more}>
              查看更多&gt;
            </span>
          ) : null}
        </p>
        {more ? (
          <React.Fragment>
            <p>
              3.密码保护：客户必须将密码保密，确保没有第三方取用其交易设施。客户同意对所有经电邮或电子交易平台传送来的指示和对所有
              {title}经由电邮、电子交易平台、电话或书面向{title}
              发出的指示确实负责，即使是由第三方发出，这些指示已和客户密码或客户签名和账户号码认证，根据
              {title}的判断相信这是客户表面授权。{title}
              并没有责任对这个表面权限作进一步查询，也没有责任因为依据这些表面权限所采取的或不采取的行动所造成之后果负责。客户须对密码的保密性、安全性及其使用独自承担责任。
            </p>
            <p>
              4.报价错误：倘若报价或成交价出现错误时，{title}
              对于账户结余相应错误并不负责。有关错误可能包括但不限于：交易员的错误报价，非国际市场价之报价、或是任何报价错误（例如由于硬件、软件或者通讯线路或系统故障导致报价错误或者第三者提供了错误的外部数据数据）。
              {title}
              不需为错误所导致的账户余额负责，客户下单时需预留足够的时间执行订单和系统计算所需保证金的时间。若订单的执行价格或订单设定和市场价格过于接近，这可能会触发其他订单（所有订单类型）或发出保证金提示。
              {title}
              不会对由于系统没有足够时间执行订单或进行运算所产生的保证金提示、账户结余或账户头寸负责。上文不得视作内容尽列，一旦发生报价或执行错误，
              {title}
              保留对有关账户进行必要更正或调整的权利。对于报价或执行错误而产生的任何争议，我们将依据绝对酌情权进行解决。若因此带来任何损失、损害或责任，客户同意予以赔偿使
              {title}不受损害。
            </p>
            <p>
              5.套戥：互联网、联机延误及报价上的误差有时会造成在{title}
              交易平台上的报价无法准确地反映实时市场价格。「套戥」及{title}
              「切汇」，或因网络连接的延误而利用差价获利的行为，并不能存在于客户直接向庄家进行买卖的场外交易市场中。
              {title}
              不容许客户在本公司的交易平台上进行此等套戥行为。依据因价格滞后带来的套戥机会进行的交易有可能会被撤销。
              {title}保留权利对涉及上述交易的账户进行必要的修正和调整。{title}
              可依据绝对酌情权，要求交易员进行干预或核准所有下单以及或终止有关客户的账户，而不需事先通知客户。对套戥及/或操控价格而产生的纠纷，
              {title}将依据绝对酌情权进行解决。{title}
              保留冻结客户提款的权利直至能够完全解决上述的问题为止。于此陈述的任何行动或决议将不会损害或放弃令
              {title}对客户和其雇员拥有之任何权力或赔偿。
            </p>
            <p>
              6.操控价格、执行及平台：{title}
              绝对严禁以任何形式对其价格、执行及平台进行操控。若{title}
              怀疑任何账户从事操控，{title}
              保留对账户进行调查及审核等的相关权利，并从涉嫌账户中扣除由相关活动所赚取的盈利款项。
              {title}保留对相关账户进行必要更正或调整的权利。{title}
              对于涉嫌从事操控的账户，{title}
              依据绝对酌情权，要求交易员进行干预、对下单进行核准以及或终止有关客户的账户。对于由套戥及或操控所产生的任何纠纷，
              {title}将依据绝对酌情权进行解决。{title}
              可依据酌情权决定向任何相关监管机构或执法机构报告有关事件。于此处所陈述的任何行动或决议并不免除或损害
              {title}对客户和其雇员拥有之任何权利或赔偿。
            </p>
            <p>
              7.客户交易对手-市场流动性：本公司不能保证客户的交易对手的信誉。本公司只有尽力与有良好声誉及可靠的机构和清算所进行交易。此外，本公司可能因为交易流动性的问题，造成交易停止，使客户不能及时为亏损的头寸进行平仓，这可能为客户带来相当的损失。本公司不会就这些损失承担任何责任。
            </p>
            <p>
              8.保证金政策（针对拓盈交易平台）：本公司的保证金政策可能要求客户提供追加资金以便维持其保证金账户，
              客户有义务满足这类保证金要求。
              否则将可能被自动平仓及造成相应的损失。
              本公司还保留拒绝接受订单的权利或提供市场对冲。
            </p>
            <p>
              9.第三方资讯或建议：如果任何非本公司雇员（下称“第三方”）向客户提供任何关于交易产品的资讯或建议，本公司不能控制或担保其关于交易产品的准确性和完整性，本公司将决不对客户因使用该信息或建议带来的损失承担任何负责。
            </p>
            <p>
              10.第三方管理交易授权或对其帐户：如果客户将交易授权或对其账户的管理交予第三方，不论是自主权或非自主权的方式，本公司将绝不对客户作出的选择负责。本公司不对有关交易的第三方作出任何声明或保证；本公司不对因为交易第三方的行为对客户产生的损失负责。因此，若客户授权第三方管理其账户，客户必须自己承担一切相关风险。如果客户未获本公司同意将交易授权或对其帐户的管理交予第三方，本公司保留对有关帐户所有或部份相关的交易进行取消的权利，而不需事先通知客户。
            </p>
            <p>
              11.监管与法律：客户应完全遵守当地有关法例，包括为遵守该等地区或司法管辖权区内须遵守之任何其他手续而取得政府或相关监管机构之同意，以及因使用本公司平台进行的交易而需支付当地任何相关税项、关税及其他金额。客户在本公司平台进行的交易，将被视为该客户向本公司属声明及保证已遵守当地法律及规定。倘客户对相关情况有疑问，客户应在开户及开始交易前应该咨询法律及其它独立的专业意见。
            </p>
          </React.Fragment>
        ) : null}
      </div>
    );
  }
}
Statement.propTypes = {
  title: PropTypes.string
};
export default Statement;
