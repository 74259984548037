import React from "react";
import { withRouter, Link } from "react-router-dom";
import classnames from "classnames";
import { category, limitNews } from "config";
import { BaseLayout, Loading } from "components";
import { getNews } from "services";
import styles from "./styles.module.scss";

class GuideList extends React.PureComponent {
  state = {
    category: [],
    loading: true
  };
  componentDidMount() {
    const { history, match } = this.props;
    const { params } = match;
    const categoryIds = category.map(item => item.id);
    if (!params.id || !categoryIds.includes(params.id)) {
      history.goBack();
    }
    const current = category.filter(item => item.id === params.id);
    this.getList(current[0]);
  }
  getList = async current => {
    if (!current) return;
    const list = [...current.children];
    const queue = current.children.map(item => getNews(item.id));
    const res = await Promise.all(queue);
    for (let i in res) {
      list[i].list = [];
      list[i].more = res[i].ch_msg.length >= limitNews;
      if (res[i].success) {
        list[i].list = [...res[i].ch_msg];
      }
    }
    this.setState({
      category: list,
      loading: false
    });
  };
  getMore = async item => {
    let ctime = 0;
    if (item.list.length > 0) {
      ctime = item.list[item.list.length - 1].ctime;
    }
    const res = await getNews(item.id, { ctime });
    if (res.success) {
      const category = this.state.category.map(val => {
        if (val.id === item.id) {
          val.list = [...val.list, ...res.ch_msg];
          val.more = res.ch_msg.length >= limitNews;
        }
        return val;
      });
      this.setState({
        category
      });
    }
  };
  componentDidUpdate(prevProps) {
    // console.log(prevProps, this.props);
  }
  render() {
    const { category, loading } = this.state;
    return (
      <BaseLayout>
        {loading ? (
          <Loading />
        ) : (
          <div className={styles.list}>
            {category.map(item => (
              <div key={item.id} className={styles.list_item}>
                <h3>{item.title}</h3>
                {item.list.length > 0 ? (
                  <ul className="list-file">
                    {item.list.map(sub => (
                      <li key={sub.id}>
                        <Link target="_blank" to={`/detail/${sub.id}`}>
                          {sub.title}
                        </Link>
                      </li>
                    ))}
                  </ul>
                ) : null}
                {item.more ? (
                  <div
                    onClick={() => this.getMore(item)}
                    className={classnames(styles.more)}
                  >
                    查看更多
                  </div>
                ) : null}
              </div>
            ))}
          </div>
        )}
      </BaseLayout>
    );
  }
}

export default withRouter(GuideList);
