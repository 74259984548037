export const isIOS = () => /iPad|iPhone|iPod/i.test(window.navigator.userAgent);
export const formatTime = (time, split = "-") => {
  const date = new Date(time);
  const year = date.getUTCFullYear();
  const month = date.getMonth() + 1 + "";
  const today = date.getDate() + "";
  const week = date.getDay() + "";
  const hours = date.getHours() + "";
  const minutes = date.getMinutes() + "";
  const seconds = date.getSeconds() + "";
  return {
    format: `${year}${split}${month.padStart(2, "0")}${split}${today.padStart(
      2,
      "0"
    )}`,
    today: today.padStart(2, "0"),
    week: week.padStart(2, "0"),
    hours: hours.padStart(2, "0"),
    minutes: minutes.padStart(2, "0"),
    seconds: seconds.padStart(2, "0")
  };
};



// 判读是否是APP 中
export const isNative = window.isNative || window.webkit ? true : false;

// native 点击
export const nativeHref = (type, data='') => {
  //android
  // if (window.isNative) {
  //   window.isNative.toAppPage && window.isNative.toAppPage('customer', '');
  // }
  // // iOS
  // if (window.webkit && window.webkit.messageHandlers.toAppPage) {
  //   window.webkit.messageHandlers.toAppPage.postMessage('customer','123');
  // }
  //android
  if (window.isNative) {
    window.isNative.toAppPage && window.isNative.toAppPage(JSON.stringify({ type, data}));
  }
  // iOS
  if (window.webkit && window.webkit.messageHandlers.toAppPage) {
    window.webkit.messageHandlers.toAppPage.postMessage(JSON.stringify({ type, data}));
  }
}

