import React from "react";
import { Link } from "react-router-dom";
import { BaseLayout } from "components";
import classnames from "classnames";
import styles from "./styles.module.scss";
class Guide extends React.PureComponent {
  render() {
    const list = [
      {
        id: 158,
        title: "投资入门",
        desc: "了解合约，快速入门",
        href: "/list",
        tag: ["开户", "交易", "出入金", "名词解释"]
      },
      {
        id: 159,
        title: "投资进阶",
        desc: "玩赚合约，提升技巧",
        href: "/list",
        tag: ["交易指导", "赢利计算", "产品介绍", "专家评论"]
      },
      {
        id: 160,
        title: "投资高手",
        desc: "业内大咖，价值赢家",
        href: "/list",
        tag: ["技巧学习", "专家观点", "教学专题", "推荐有奖"]
      },
      {
        id: 173,
        title: "技术指标",
        desc: "走势分析，投资必看",
        href: "/list",
        tag: ["MACD", "RSI", "KDJ", "BBI"]
      }
    ];
    return (
      <BaseLayout>
        <div className={styles.banner} />
        <div className={styles.list}>
          {list.map(val => (
            <div key={val.id} className={styles.list_item}>
              <div className={styles.list_item_head}>
                <Link
                  target="_blank"
                  to={`${val.href}/${val.id}`}
                  className="block-show"
                >
                  <h3 className={styles.list_item_title}>{val.title}</h3>
                  <p className={styles.list_item_desc}>{val.desc}</p>
                </Link>
              </div>
              <div
                className={classnames(
                  "flex-container list-gutter",
                  styles.tags
                )}
              >
                {val.tag &&
                  val.tag.map((item, index) => <span key={index}>{item}</span>)}
              </div>
            </div>
          ))}
        </div>
      </BaseLayout>
    );
  }
}

export default Guide;
