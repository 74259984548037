import React from "react";
import { withRouter, Link } from "react-router-dom";
import classnames from "classnames";
import { BaseLayout, Loading, List } from "components";
import { getCategory, getNews } from "services";
import { debounce } from "lodash";
import styles from "./index.module.scss";
class Faq extends React.PureComponent {
  constructor() {
    super();
    this.debounceSearchNews = debounce(this.debounceSearchNews, 300);
  }
  state = {
    id: null,
    category: [],
    hots: [],
    searchList: [],
    loading: true,
    searching: false
  };
  componentDidMount() {
    const { history, match } = this.props;
    const { params } = match;
    document.title = "帮助中心";
    if (!params.id) {
      history.goBack();
    }
    this.init(params.id);
  }
  init = async id => {
    try {
      let category = [],
        hots = [];
      const [clist, hlist] = await Promise.all([
        getCategory(id),
        getNews(id, { isHot: true, limit: 15 })
      ]);
      if (clist.success) {
        category = [...clist.ch_msg];
      }
      if (hlist.success) {
        hots = [...hlist.ch_msg];
      }
      this.setState({
        id,
        loading: false,
        category,
        hots
      });
    } catch (e) {
      console.log(e);
    }
  };
  search = () => {
    document.body.classList.add("scroll-fixed");
    this.setState({
      searching: true
    });
  };
  searchNews = e => {
    e.persist();
    this.debounceSearchNews(e.target.value);
  };
  debounceSearchNews = async keywords => {
    if (keywords) {
      try {
        const res = await getNews(this.state.id, { limit: 100, keywords });
        if (res.success) {
          this.setState({
            searchList: [...res.ch_msg]
          });
        }
      } catch (e) {
        console.log(e);
      }
    } else {
      this.setState({
        searchList: []
      });
    }
  };

  cancelSearch = () => {
    document.body.classList.remove("scroll-fixed");
    this.setState({
      searching: false,
      searchList: []
    });
  };
  render() {
    const { loading, category, hots, searching, searchList } = this.state;
    return (
      <BaseLayout>
        {loading ? (
          <Loading />
        ) : (
          <React.Fragment>
            {searching ? (
              <div
                className={classnames("fixed full-screen", styles.search_box)}
              >
                <div
                  className={classnames(
                    "flex-container flex-y-center flex-space-between",
                    styles.searchbar
                  )}
                >
                  <input
                    onChange={this.searchNews}
                    className="flex-block"
                    type="text"
                    placeholder="搜索"
                  />
                  <button onClick={this.cancelSearch}>取消</button>
                </div>
                <div className={styles.search_list}>
                  {searchList.length > 0 ? (
                    <List list={searchList} />
                  ) : (
                    <div className="text-center">没有搜到相关的内容。</div>
                  )}
                </div>
              </div>
            ) : null}
            {/* <Navigation>
              <a
                rel="noopener noreferrer"
                target="_blank"
                href="https://btcccfd.kf5.com/kchat/38726?from=%E5%9C%A8%E7%BA%BF%E6%94%AF%E6%8C%81&group=0"
              >
                <i className="icon icon-online" />
              </a>
            </Navigation> */}
            <div
              onClick={this.search}
              className={classnames(
                "flex-container flex-center",
                styles.search
              )}
            >
              <button className={classnames("flex-block", styles.search_btn)}>
                <i className="icon icon-search" />
                搜索
              </button>
            </div>
            <div
              className={classnames(
                "flex-container flex-y-center  flex-wrap",
                styles.category
              )}
            >
              {category.map(item => (
                <Link
                  to={{
                    pathname: `/cat/${item.id}`,
                    search: `?title=${item.name}`
                  }}
                  key={item.id}
                >
                  <span
                    className={styles.category_item}
                    style={{ backgroundImage: `url(${item.image})` }}
                  >
                    {item.name}
                  </span>
                </Link>
              ))}
            </div>
            <h3 className={styles.title}>常见问题</h3>
            {hots.length > 0 ? <List list={hots} /> : null}
          </React.Fragment>
        )}
      </BaseLayout>
    );
  }
}
export default withRouter(Faq);
