import React from "react";
import classnames from "classnames";
import { BaseLayout, Footer } from "components";
import { isIOS } from "utils";
import { linkConfig, webCfg } from "config";
import { getAndroidList } from "services";
import styles from "./styles.module.scss";
import { parse } from "qs";
class Download extends React.PureComponent {
  state = {
    androidUrl: linkConfig.download.android.anto,
    iosUrl: linkConfig.download.ios.anto,
    loading: false,
    title: "立即下载",
    slogan: "下载 汇鑫金业 APP",
    platform: "huixin",
    detail: "/download/detail",
    // download_beta: linkConfig.download.ios.anto,
    step: false,
  };
  constructor(props) {
    super(props);
    this.$download = React.createRef();
    this.$copy = React.createRef();
  }
  componentDidMount() {
    if (window.location.search) {
      const params = parse(window.location.search.replace(/\?/, ""));
      if (
        params.platform &&
        params.url &&
        Object.keys(linkConfig.download.ios).includes(params.platform)
      ) {
        this.setState({
          androidUrl: params.url,
          slogan: params.title,
          platform: params.platform,
          detail: `${this.state.detail}${window.location.search}`,
        });
      } else if (params.utm_source) {
        this.getList(params.utm_source);
      }
    }
  }

  getList = async (fromSource) => {
    const res = await getAndroidList({ fromSource });
    if (res.success && res.channelPackage) {
      this.setState({
        androidUrl: res.channelPackage.fileUrl,
      });
    }
  };
  hideDetail = () => {
    this.setState({
      step: false,
    });
  };
  startDownload = () => {
    this.setState({
      loading: true,
      title: isIOS() ? "立即下载" : "获取中...",
      step: true,
    });
    this.$download.current && this.$download.current.click();
    this.$timer = setTimeout(() => {
      this.setState({
        loading: false,
        title: "立即下载",
      });
      clearTimeout(this.$timer);
    }, 3000);
  };
  componentWillUnmount() {
    clearTimeout(this.$timer);
  }
  render() {
    const ua = window.navigator.userAgent.toLowerCase();
    const isWeixinBrowser = !!ua.match(/MicroMessenger/i);
    const url = isIOS() ? this.state.iosUrl : this.state.androidUrl;
    return (
      <BaseLayout>
        <div className={classnames("full-screen", styles.download)}>
          {isWeixinBrowser ? (
            <div
              className={classnames(styles.shadow, {
                [styles.android]: !isIOS(),
              })}
            />
          ) : null}
          <h2>顶尖平台 卓越体验</h2>
          <p className={styles.summary}>操作简单易用，随时随地交易</p>

          <div
            className={styles.animate}
            style={{
              backgroundImage: `url("/download/logo.png")`,
            }}
          />
          <h3>{this.state.slogan} </h3>
          <p className={styles.strength}>
            <span>开放</span>
            <span>创新</span>
            <span>安全</span>
            <span>可信</span>
          </p>
          <div className={styles.download_wrapper}>
            <div className="flex-container flex-center">
              {isIOS() ? (
                <div className={styles.ios_down}>
                  <a
                    href={"https://apps.apple.com/cn/app/id1600200673"}
                    className={classnames(
                      "flex-block active",
                      styles.download_btn_store,
                      {
                        [styles.loading]: this.state.loading,
                      }
                    )}
                  >
                    <span className={styles.icon_ios}></span>
                    <span className={styles.text}>汇鑫金业</span>
                    <span className={styles.icon}></span>
                  </a>
                </div>
              ) : (
                <div
                  onClick={this.startDownload}
                  className={classnames(
                    "flex-block active",
                    styles.download_btn,
                    {
                      [styles.loading]: this.state.loading,
                    }
                  )}
                >
                  {console.log(this.state)}
                  {this.state.title}
                </div>
              )}
            </div>

            {/* {this.state.step && isIOS() ? (
              <div
                className={classnames(
                  styles.install,
                  "flex-container flex-y-end"
                )}
              >
                <div>
                  <div
                    onClick={this.hideDetail}
                    className={classnames("close", styles.close)}
                  />
                  <h3 className="text-center">安装信息</h3>
                  <p>
                    APP安装完成后，打开APP时，若出现弹窗提示“未受信任的企业级开发者”，需先对下方描述文件进行授信，授信方式可点击底部“快速信任”或查看
                    <a className={styles.link} href="/download/detail/btcc">
                      详细安装教程&gt;
                    </a>
                    <img
                      src={`http://www.wstjy.cn/app/iosazjc/${
                        this.state.platform
                      }/t_0.png`}
                      alt=""
                    />
                  </p>
                  <p>
                    <a
                      href="https://tradeglm.com/source/material/btcc.mobileprovision"
                      className={styles.confirm}
                    >
                      快速信任
                    </a>
                  </p>
                </div>
              </div>
            ) : null} */}
          </div>
          <a
            ref={this.$download}
            href={url}
            rel="noopener noreferrer"
            className={styles.download_link}
          >
            立即下载
          </a>

          <Footer title={webCfg[this.state.platform].copyright} />
        </div>
      </BaseLayout>
    );
  }
}

export default Download;
