import React from "react";
import { withRouter, Link } from "react-router-dom";
import classnames from "classnames";
import { BaseLayout, Loading } from "components";
import { getNews, getCategory } from "services";
import PerfectScrollbar from "react-perfect-scrollbar";
import "react-perfect-scrollbar/dist/css/styles.css";
import styles from "./styles.module.scss";

class GuideList extends React.PureComponent {
  state = {
    activeId: null,
    list: [],
    category: [],
    loading: true
  };
  componentDidMount() {
    const { history, match } = this.props;
    const { params } = match;
    document.title = "帮助中心";
    if (!params.id) {
      history.goBack();
    }
    this.getChildCatgory(params.id);
  }
  getChildCatgory = async id => {
    try {
      const res = await getCategory(id);
      if (res.success) {
        this.setState({
          loading: false,
          category: [...res.ch_msg],
          activeId: res.ch_msg[0] && res.ch_msg[0].id
        });
        if (res.ch_msg[0]) {
          await this.getList(res.ch_msg[0].id);
        }
      }
    } catch (e) {
      console.log(e);
    }
  };
  getList = async id => {
    try {
      const res = await getNews(id, { limit: 200 });
      if (res.success) {
        this.setState({
          activeId: id,
          list: [...res.ch_msg]
        });
      }
    } catch (error) {
      console.log(error);
    }
  };
  render() {
    const { category, loading, list, activeId } = this.state;
    return (
      <BaseLayout>
        {loading ? (
          <Loading />
        ) : (
          <React.Fragment>
            <div className={styles.banner}>欢迎来到帮助中心</div>
            <div className={styles.scroll_container}>
              <div className={styles.scroll_menu}>
                <PerfectScrollbar>
                  {category.map(item => (
                    <div
                      className={styles.menu_item}
                      key={item.id}
                      onClick={() => this.getList(item.id)}
                    >
                      <span
                        className={classnames({
                          [styles.active]: activeId === item.id
                        })}
                      >
                        {item.name}
                      </span>
                    </div>
                  ))}
                </PerfectScrollbar>
              </div>
              <div className={styles.scroll_list}>
                {list.length ? (
                  <PerfectScrollbar>
                    {list.map(item => (
                      <p key={item.id} className={styles.list_item}>
                        <Link target="_blank" to={`/detail/${item.id}`}>
                          <span>{item.title}</span>
                        </Link>
                      </p>
                    ))}
                  </PerfectScrollbar>
                ) : (
                  <div className="text-center tips">暂无内容</div>
                )}
              </div>
            </div>
          </React.Fragment>
        )}
      </BaseLayout>
    );
  }
}

export default withRouter(GuideList);
